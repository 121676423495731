div{
    margin-top: 10px;
}

h3{
  background-color: black;
  color: wheat;
  text-align: center;
  padding: 5px;
  border-radius: 10px;
}
.label {
  width: 30%;
  font-weight: 600;
  font-size: 20px;
  margin-top: 5px;
}
.input {
  width: 40%;
  height: 40px;
  margin-top: 10px;
  border-radius: 7px;
}
textarea {
  width: 40%;
  height: 70px;
  margin-top: 10px;
}

.usage {
  
  display: inline;
  input {
    width: 5%;
    height: 20px;
    margin-top: 10px;
  }
  p {
    display: inline-block;
    font-size: 19px;
    font-weight: 600;
    padding-left: 5px;
  }
}

.added {
    font-size: 40px;
    font-weight: bold;
    cursor: pointer;

}

.ingrendients{
    display: inline-block;
    margin-left: 5px;
    margin-right: 10px;
    background-color: black;
    padding: 8px;
    text-transform: capitalize;
    border-radius: 10px;
    cursor: pointer;
    color: white;
}

.submit {
  margin: auto;
  display: flex;
  padding: 5px;
  width: 10%;
  margin-top: 20px;
  border-radius: 10px;
  background-color: yellowgreen;
  font-weight: bold;

}


/* Add this CSS to your stylesheet */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.modal-content h4 {
  margin-bottom: 20px;
}

.modal-content label {
  display: block;
  margin-bottom: 10px;
  text-align: left;
}

.modal-content input {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.modal-content button {
  padding: 10px 20px;
  margin: 5px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.modal-content button:first-of-type {
  background-color: #28a745;
  color: white;
}

.modal-content button:last-of-type {
  background-color: #dc3545;
  color: white;
}

.modal-content p {
  margin-bottom: 10px;
  text-align: left;
}

.view-button {
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
}
.login-container {
    max-width: 400px;
    margin: 50px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #f9f9f9;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.login-container h2 {
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
}

.login-container form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.login-container label {
    margin-bottom: 10px;
    font-size: 14px;
    color: #555;
    width: 100%;
    display: flex;
    flex-direction: column;
    font-weight: bold;
}

.login-container input {
    padding: 10px;
    margin-top: 5px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
    width: 100%;
    box-sizing: border-box;
}

.login-container button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: #007bff;
    color: white;
    font-size: 16px;
    transition: background-color 0.3s;
    align-self: center;
}

.login-container button:disabled {
    background-color: #007bff;
    opacity: 0.7;
    cursor: not-allowed;
}

.login-container button:hover:not(:disabled) {
    background-color: #0056b3;
}
.tab {
    cursor: pointer;
    font-size: inherit;
    font-weight: bold;
    border-bottom: 1px solid grey ;
    padding: 5px;
}

.active_tab {
    cursor: pointer;
    background-color: black;
    color: white;
    font-size: inherit;
    font-weight: bold;
    padding: 5px;
}


    option{

        font-size: 16px;
        font-weight: 600;
        margin-top: 59px;

    }
